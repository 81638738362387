import type {Cindedi} from '@cindedi/spec/application';
import {createServiceProvider} from '@cindedi/service-provider';
import {CindediError} from '@cindedi/error';
import {BrowserCryptoEncrypter} from './classes/BrowserCryptoEncrypter';
import {isInvalidCryptoKey} from './guards/isInvalidCryptoKey';

export const EncryptionServiceProvider = createServiceProvider(
  (
    getConfiguration: (application: Cindedi.Application) => Application.EncryptionConfigurations,
  ) => {
    return {
      name: 'encryption',

      registerConfiguration(application) {
        application.configuration.set('encryption', {
          ...getConfiguration(application),
        });
      },

      bootConfiguration(application) {
        const config = application.configuration.get('encryption')!;

        if (isInvalidCryptoKey(config.key ?? '')) {
          throw new CindediError({
            status: 500,
            title: 'Invalid Configuration',
            detail:
              'Encryption key must be 128 bits (32 hex characters) or 256 bits (64 hex characters)',
          });
        }

        if (isInvalidCryptoKey(config.iv ?? '')) {
          throw new CindediError({
            status: 500,
            title: 'Invalid Configuration',
            detail:
              'Encryption IV must be 128 bits (32 hex characters) or 256 bits (64 hex characters)',
          });
        }
      },

      register({configuration, container}) {
        container.factory(
          'encryption',
          () => new BrowserCryptoEncrypter(configuration.get('encryption')!),
        );
      },
    };
  },
);
