export interface Defer<T = void> {
  promise: Promise<T>;
  resolve: (value?: T) => void;
  reject: (reason?: any) => void;
}

export function defer<T = void>(value?: T): Defer<T> {
  const deferrable: Defer<T> = {
    promise: Promise.resolve(value as T),
    resolve: (v = value) => v,
    reject: (reason) => reason,
  };

  deferrable.promise = new Promise<T>((res, rej) => {
    deferrable.resolve = (v = value) => res(v as T);
    deferrable.reject = rej;
  });

  return deferrable;
}
