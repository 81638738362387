import {CookieStorage} from '@cindedi/cookie-storage';
import {BrowserCookieClient} from './classes/BrowserCookieClient';
import {createServiceProvider} from '@cindedi/service-provider';

export const CookieProvider = createServiceProvider(() => {
  return {
    name: 'cookie',

    register({container}) {
      container.singleton('CookieClient', BrowserCookieClient);
      container.factory('cookies', () => new CookieStorage(container.use('CookieClient')));
    },

    async boot({container}) {
      await container.use('CookieClient').ready;
    },
  };
});
