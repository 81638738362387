import type {Cindedi} from '@cindedi/spec/application';
import {ApplicationContext} from '../../context/ApplicationContext';

export interface ApplicationProviderProps {
  app: Cindedi.Application;
  children: React.ReactNode;
}

export function ApplicationProvider({app, children}: ApplicationProviderProps) {
  return <ApplicationContext.Provider value={app}>{children}</ApplicationContext.Provider>;
}
