import type {Cindedi} from '@cindedi/spec/service-container';

export function isServiceDefinition(
  maybeServiceDefinition: any,
): maybeServiceDefinition is Cindedi.ServiceDefinition<any> {
  return (
    maybeServiceDefinition != null &&
    typeof maybeServiceDefinition === 'object' &&
    !Array.isArray(maybeServiceDefinition) &&
    maybeServiceDefinition.dependencies != null &&
    maybeServiceDefinition.namespace != null &&
    maybeServiceDefinition.value != null
  );
}
