import {createServiceProvider} from '@cindedi/service-provider';

export {WindowEnvironment} from './components/WindowEnvironment';

export const WindowEnvironmentProvider = createServiceProvider(() => {
  return {
    name: 'window-environment',

    registerEnvironment({environment}) {
      if (typeof window === 'undefined') return;
      if ('ENV' in window) environment.set(window.ENV as Partial<Application.EnvironmentVariables>);
    },
  };
});
