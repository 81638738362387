import type {Cindedi} from '@cindedi/spec/error';
import {normalizeError} from '../utilities/normalizeError';
import {CindediError} from './CindediError';

export class AggregateCindediError extends CindediError {
  private _errors: Cindedi.Error[] = [];

  get errors() {
    return this._errors;
  }

  get hasAny() {
    return this._errors.length > 0;
  }

  push(error: Cindedi.Error | Error) {
    this._errors = this._errors.concat(normalizeError(error));
  }

  clear() {
    this._errors = [];
  }

  serialize(options: Cindedi.ErrorSerializerOptions = {}): Cindedi.ErrorMessage[] {
    return this._errors.flatMap((error) => error.serialize(options));
  }
}
