import type {Cindedi} from '@cindedi/spec/cookie';
import {defer} from '@cindedi/utilities/defer';

export class CookieStorage implements Cindedi.CookieStorage {
  protected client: Cindedi.CookieClient;

  protected jarIndex: Set<Cindedi.Cookie['name']> = new Set();

  protected jar: Record<Cindedi.Cookie['name'], Cindedi.Cookie> = {};

  #ready = defer();

  get ready() {
    return this.#ready.promise;
  }

  constructor(client: Cindedi.CookieClient) {
    this.client = client;
    this.hydrate();
  }

  protected async hydrate() {
    await this.client.ready;
    this.client.parse().forEach((cookie) => {
      if (!this.jarIndex.has(cookie.name)) {
        this.jarIndex.add(cookie.name);
      }

      this.jar[cookie.name] = cookie;
    });

    this.#ready.resolve();
  }

  get(name: string): Cindedi.Cookie | null {
    if (this.has(name)) {
      return this.jar[name];
    }

    return null;
  }

  set(
    name: string,
    value: any,
    options: Omit<Cindedi.Cookie, 'name' | 'value' | 'unsafeValue'> = {},
  ): Cindedi.Cookie {
    const cookie: Cindedi.Cookie = {
      ...options,
      name,
      value,
    };

    this.jar[cookie.name] = cookie;
    this.jarIndex.add(cookie.name);
    this.client.set(cookie);

    return cookie;
  }

  remove(name: string): Cindedi.Cookie | null {
    if (this.has(name)) {
      const cookie = this.get(name) ?? {};
      this.set(name, {}, {...cookie, expires: new Date('01/01/1970')});
      delete this.jar[name];
      this.jarIndex.delete(name);
    }

    return null;
  }

  has(name: string): boolean {
    return this.jarIndex.has(name);
  }

  clear(): boolean {
    this.jarIndex.forEach((cookie) => this.remove(cookie));
    this.flush();

    return true;
  }

  keys(): string[] {
    return [...this.jarIndex];
  }

  key(index: number): Cindedi.Cookie | null {
    return this.jar[this.keys()[index]] ?? null;
  }

  protected flush() {
    this.jar = {};
    this.jarIndex.clear();
  }
}
