import {CircularDependencyError} from '../errors/CircularDependencyError';
import type {ServiceContainer} from '../classes/ServiceContainer';

export function checkForCircularDependencies<Services extends Record<any, any>>(
  container: ServiceContainer,
  reference: keyof Services,
  breadcrumbs: (keyof Services)[] = [reference],
) {
  const binding = container._getBinding(reference);
  if (binding && typeof binding === 'object') {
    binding.dependencies.forEach((dependency) => {
      if (breadcrumbs.includes(dependency))
        throw new CircularDependencyError(breadcrumbs.concat(dependency));

      checkForCircularDependencies(container, dependency, breadcrumbs.concat(dependency));
    });
  }
}
