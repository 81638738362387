import type {Cindedi} from '@cindedi/spec/cookie';
import {cookieEncoder, cookieParser} from '@cindedi/utilities/cookies';

export class BrowserCookieClient implements Cindedi.CookieClient {
  get #cookies() {
    return cookieParser(document.cookie);
  }

  #ready = Promise.resolve(this);

  get ready() {
    return this.#ready;
  }

  parse(): Cindedi.Cookie[] {
    return this.#cookies;
  }

  set(cookie: Cindedi.Cookie): void {
    document.cookie = cookieEncoder(cookie);
  }

  async prepare(): Promise<string[]> {
    return this.#cookies.map(cookieEncoder);
  }
}
