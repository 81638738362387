import {signal} from '@preact/signals-core';

export function MenuApi(): Organization.MenuApi {
  const menuVisibility = signal(false);

  return {
    get isVisible() {
      return menuVisibility.value;
    },
    get isClosed() {
      return menuVisibility.value === false;
    },
    toggle() {
      menuVisibility.value = !menuVisibility.value;
    },
    show() {
      menuVisibility.value = true;
    },
    hide() {
      menuVisibility.value = false;
    },
  };
}
