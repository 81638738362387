import type {Cindedi} from '@cindedi/spec/lifecycle-container';
import {normalizeError} from '@cindedi/error';
import {EventEmitter} from '@cindedi/event-emitter';
import {generateId} from '@cindedi/utilities/generateId';

export class LifecycleContainer
  extends EventEmitter<Cindedi.LifecycleContainerEvents>
  implements Cindedi.LifecycleContainer
{
  private _id = generateId('lifecycle-container');

  private _telemetry: Cindedi.Telemetry;

  private _serviceProviders: Cindedi.ServiceProvider[];

  // @ts-ignore
  private _lifecycleMap: Map<keyof Cindedi.Lifecycles, Cindedi.ServiceProvider[]> = new Map();

  private _contextCreator: Cindedi.ContextCreator;

  private _providerTelemetryClients: Record<string, Cindedi.Telemetry> = {};

  private _eventEmitter?: Cindedi.EventEmitter<Cindedi.LifecycleContainerEvents>;

  get id() {
    return this._id;
  }

  constructor(
    providers: Cindedi.ServiceProvider[],
    contextCreator: Cindedi.ContextCreator,
    telemetry: Cindedi.Telemetry,
    parent?: Cindedi.EventEmitter,
  ) {
    super(parent);
    this._eventEmitter = parent;
    this._serviceProviders = providers;
    this._contextCreator = contextCreator;
    this._telemetry = telemetry.fork('lifecycle', {detail: {owner: this}});

    for (const provider of providers) {
      for (const [name, lifecycle] of Object.entries(provider)) {
        if (typeof lifecycle === 'function') {
          const list = this._lifecycleMap.get(name as keyof Cindedi.Lifecycles) ?? [];
          this._lifecycleMap.set(name as keyof Cindedi.Lifecycles, list.concat(provider));
        }
      }
    }
  }

  async parallel<Name extends keyof Cindedi.Lifecycles = keyof Cindedi.Lifecycles>(
    name: Name,
    args?: Cindedi.ArgumentsOf<Name>,
  ): Promise<Awaited<ReturnType<Cindedi.Lifecycles[Name]>>[]> {
    const serviceProviders = this._lifecycleMap.get(name);
    return serviceProviders
      ? await this._telemetry.measureAsyncCallback(
          `${name}`,
          async () =>
            await Promise.all(
              serviceProviders.map((provider) =>
                this._getTelemetryFor(provider.name).measureAsyncCallback(`${name}`, async () => {
                  try {
                    const fn = provider[name];
                    if (fn && typeof fn === 'function') {
                      const context = this._contextCreator();
                      return (fn as Function).apply(context, args ?? []);
                    }
                  } catch (maybe) {
                    this._handleError(maybe, {provider: provider.name, lifecycle: name});
                  }
                }),
              ),
            ),
        )
      : [];
  }

  async sequential<Name extends keyof Cindedi.Lifecycles = keyof Cindedi.Lifecycles>(
    name: Name,
    args?: Cindedi.ArgumentsOf<Name>,
  ): Promise<Awaited<ReturnType<Cindedi.Lifecycles[Name]>>[]> {
    const serviceProviders = this._lifecycleMap.get(name);
    return serviceProviders
      ? await this._telemetry.measureAsyncCallback(`${name}`, async () => {
          const results: Awaited<ReturnType<Cindedi.Lifecycles[Name]>>[] = [];
          for (const provider of serviceProviders) {
            results.push(
              await this._getTelemetryFor(provider.name).measureAsyncCallback(
                `${name}`,
                async () => {
                  try {
                    const fn = provider[name];
                    if (fn && typeof fn === 'function') {
                      const context = this._contextCreator();
                      return await (fn as Function).apply(context, args ?? []);
                    }
                  } catch (maybe) {
                    this._handleError(maybe, {provider: provider.name, lifecycle: name});
                  }
                },
              ),
            );
          }
          return results;
        })
      : [];
  }

  sequentialSync<Name extends keyof Cindedi.Lifecycles = keyof Cindedi.Lifecycles>(
    name: Name,
    args?: Cindedi.ArgumentsOf<Name>,
  ): Awaited<ReturnType<Cindedi.Lifecycles[Name]>>[] {
    const serviceProviders = this._lifecycleMap.get(name);
    return serviceProviders
      ? this._telemetry.measureCallback(`${name}`, () =>
          serviceProviders.map((provider) =>
            this._getTelemetryFor(provider.name).measureCallback(`${name}`, () => {
              try {
                const fn = provider[name];
                if (fn && typeof fn === 'function') {
                  const context = this._contextCreator();
                  return (fn as Function).apply(context, args ?? []);
                }
              } catch (maybe) {
                this._handleError(maybe, {provider: provider.name, lifecycle: name});
              }
            }),
          ),
        )
      : [];
  }

  fork(
    providers: Cindedi.ServiceProvider[],
    contextCreator: Cindedi.ContextCreator = this._contextCreator,
    telemetry: Cindedi.Telemetry = this._telemetry,
    eventEmitter: Cindedi.EventEmitter<Cindedi.LifecycleContainerEvents> = this._eventEmitter ??
      (this as any),
  ): LifecycleContainer {
    return new LifecycleContainer(
      this._serviceProviders.concat(providers),
      contextCreator,
      telemetry,
      eventEmitter,
    );
  }

  private _getTelemetryFor(name: string) {
    this._providerTelemetryClients[name] ||= this._telemetry.fork(name, {detail: {owner: this}});
    return this._providerTelemetryClients[name];
  }

  private _handleError(maybe: unknown, extras?: Record<string, any>) {
    const error = normalizeError(maybe);
    if (extras) error.set({extras});
    throw error;
  }
}
