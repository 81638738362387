import type {Cindedi as _} from '@cindedi/spec/error';
import {CindediError} from '@cindedi/error';

export class InvalidServiceDefinitionError extends CindediError {
  constructor(definition: unknown) {
    super({
      title: 'Invalid Service Definition',
      status: 500,
      detail: `Failed to register service with the service container due to an invalid service definition. ${
        typeof definition === 'string'
          ? `Expected a service constructor but got "undefined" while registering "${definition}"`
          : `Expected a string or a Cindedi.ServiceDefinition, but got "${typeof definition}"`
      }`,
    });
  }
}
