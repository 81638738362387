import type {Cindedi} from '@cindedi/spec/event-emitter';
import {generateId} from '@cindedi/utilities/generateId';
import type {EventOptions} from '../types';

export class Event<
  Type extends string | number | symbol = string,
  Data extends Record<any, any> = Record<any, any>,
> implements Cindedi.Event<Type, Data>
{
  private _id = generateId('event');
  private _bubbles: boolean;
  private _currentTarget: Cindedi.EventEmitter<{[Name in Type]: Data}> | null;
  private _target: Cindedi.EventEmitter<{[Name in Type]: Data}> | null;
  private _timeStamp: number;
  private _type: Type;
  private _data: Data;
  private _immediatePropagationStopped: boolean = false;
  private _propagationStopped: boolean = false;
  private _composedPath: Cindedi.EventEmitter<{[Name in Type]: Data}>[];

  get id(): string {
    return this._id;
  }

  get bubbles(): boolean {
    return this._bubbles;
  }

  get currentTarget(): Cindedi.EventEmitter<{
    [Name in Type]: Data;
  }> | null {
    return this._currentTarget;
  }

  get target(): Cindedi.EventEmitter<{[Name in Type]: Data}> | null {
    return this._target;
  }

  get timeStamp(): number {
    return this._timeStamp;
  }

  get type(): Type {
    return this._type;
  }

  get data(): Data {
    return this._data;
  }

  get immediatePropagationStopped(): boolean {
    return this._immediatePropagationStopped;
  }

  get propagationStopped(): boolean {
    return this._propagationStopped;
  }

  constructor(
    type: Type,
    data: Data = undefined as any,
    {
      bubbles = true,
      currentTarget = null,
      timeStamp = Date.now(),
      composedPath = [],
    }: Partial<EventOptions> = {},
  ) {
    this._bubbles = bubbles;
    this._currentTarget = currentTarget;
    this._timeStamp = timeStamp;
    this._type = type;
    this._data = data;
    this._composedPath = currentTarget ? composedPath.concat(currentTarget) : composedPath;
    this._target = this._composedPath[0] ?? currentTarget;
  }

  composedPath(): Cindedi.EventEmitter<{[Name in Type]: Data}>[] {
    return this._composedPath.slice();
  }

  stopImmediatePropagation(): void {
    this._immediatePropagationStopped = true;
  }

  stopPropagation(): void {
    this._propagationStopped = true;
  }
}
