import type {Cindedi} from '@cindedi/spec/error';
import {CindediError} from '../classes/CindediError';
import {CINDEDI_ERROR_ID} from '../constants/symbols';
import {isError} from '../guards/isError';
import {isCindediError} from '../guards/isCindediError';

export function normalizeError(value: any): Cindedi.Error {
  if (isCindediError(value)) return value;

  if (isError(value)) {
    (value as any)[CINDEDI_ERROR_ID] = true;
    value = value as Cindedi.Error;
    value.status = 500;
    value._message = {
      status: 500,
      title: 'Internal Server Error',
      detail: value.message,
    };
    value.set = (partial: Partial<Cindedi.ErrorMessage>) => {
      value._message = {...value._message.extras, ...partial};
      return value;
    };
    value.serialize = (options: Cindedi.ErrorSerializerOptions = {}) =>
      options.includeStack ? [{...value._message, stack: value.stack}] : [value._message];

    return value;
  }

  return new CindediError(
    JSON.stringify(`Non-standard value thrown while running application: ${value}`),
  );
}
