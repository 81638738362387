import {Event, EventEmitter} from '@cindedi/event-emitter';
import type {Cindedi} from '@cindedi/spec/configuration';
import {generateId} from '@cindedi/utilities/generateId';
import {getPath} from '@cindedi/utilities/getPath';
import {setPath} from '@cindedi/utilities/setPath';
import type {PathsOf, PathValue} from '@cindedi/spec/utilities/DotNotation';

export class Configuration<Definitions extends Record<string, any> = Record<string, any>>
  extends EventEmitter<Cindedi.ConfigurationEvents>
  implements Cindedi.Configuration<Definitions>
{
  private _id = generateId('configuration');

  private _parentConfiguration?: Cindedi.Configuration<Definitions>;

  private _definitions: Partial<Definitions>;

  get id(): string {
    return this._id;
  }

  constructor(
    partial: Partial<Definitions> = {},
    eventTarget?: EventEmitter | Configuration<any>,
    parent?: Configuration<any>,
  ) {
    super(eventTarget);
    this._definitions = partial;
    if (parent) {
      this._parentConfiguration = parent as any;
      (this._parentConfiguration as Configuration<any>).addEventListener(
        'configuration:set',
        (event) => this.dispatchEvent(event),
      );
    }
  }

  get<Key extends PathsOf<Definitions>>(key: Key): PathValue<Definitions, Key> | undefined;
  get<Key extends PathsOf<Definitions>>(
    key: Key,
    fallback: PathValue<Definitions, Key>,
  ): PathValue<Definitions, Key>;
  get<Key extends PathsOf<Definitions>>(
    key: Key,
    fallback?: PathValue<Definitions, Key>,
  ): PathValue<Definitions, Key> | undefined;
  get<Key extends PathsOf<Definitions>>(
    key: Key,
    fallback?: PathValue<Definitions, Key>,
  ): PathValue<Definitions, Key> | undefined {
    const result = getPath(this._definitions, key) as PathValue<Definitions, Key> | undefined;

    if (result !== undefined) {
      return result;
    } else if (this._parentConfiguration) {
      return this._parentConfiguration.get(key, fallback);
    }

    return fallback;
  }

  set<Path extends PathsOf<Definitions>>(path: Path, value: PathValue<Definitions, Path>): this {
    setPath(this._definitions, (path as string).split('.'), value);
    this.dispatchEvent(new Event('configuration:set', {[path]: value}));

    return this;
  }

  has<Key extends PathsOf<Definitions>>(key: Key): boolean {
    return getPath(this._definitions, key) !== undefined;
  }

  missing<Name extends keyof Definitions>(name: Name): boolean {
    return !this.has(name);
  }

  fork<ForkedVariables extends Record<string, any>>(
    variables: Partial<ForkedVariables> = {},
    eventTarget?: EventEmitter,
  ): Cindedi.Configuration<ForkedVariables & Definitions> {
    return new Configuration<ForkedVariables & Definitions>(
      variables as ForkedVariables & Definitions,
      eventTarget,
      this as Configuration<any>,
    );
  }
}
