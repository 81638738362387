import type {Cindedi} from '@cindedi/spec/service-container';
import type {Constructor} from '@cindedi/spec/utilities/Constructor';
import type {Binding} from '../classes/Bindings';
import type {ServiceContainer} from '../classes/ServiceContainer';
import {checkForCircularDependencies} from './checkForCircularDependencies';

export function createServiceFactory<Services extends Record<any, any>, K extends keyof Services>(
  serviceContainer: ServiceContainer,
  namespace: K,
): Cindedi.ServiceFactory<Services, K> {
  return function defaultFactory(container) {
    const instances: Services[keyof Services][] = [];
    const binding = serviceContainer._getBinding(namespace) as Binding<Services[K]>;

    checkForCircularDependencies(serviceContainer, binding.reference);

    for (const dependency of binding.dependencies) {
      instances.push(container.use(dependency));
    }

    return new (binding.original as Constructor<Services[K], typeof instances>)(...instances);
  };
}
