import type {Cindedi} from '@cindedi/spec/encryption';

export class BrowserCryptoEncrypter implements Cindedi.Encrypter {
  constructor(private readonly configurations: Application.EncryptionConfigurations) {}

  async encrypt(value: any, options: Cindedi.EncryptOptions = {}): Promise<string> {
    const serializedValue = this.#shouldSerialize(options) ? this.#serialize(value) : value;
    const encoder = new TextEncoder();
    const data = encoder.encode(serializedValue);

    const cipherText = await crypto.subtle.encrypt(
      {name: 'AES-CBC', iv: await this.#getIv(options)},
      await this.#getKey(options),
      data,
    );

    const base64CipherText = this.#arrayBufferToBase64(cipherText);
    return this.#shouldSign(options) ? this.#sign(base64CipherText) : base64CipherText;
  }

  async decrypt(payload: string, options: Cindedi.DecryptOptions = {}): Promise<string> {
    let payloadToDecrypt = payload;
    if (this.#shouldSign(options)) {
      payloadToDecrypt = await this.#unsign(payload);
      if (!payloadToDecrypt) throw new Error('Invalid signature');
    }

    const data = this.#base64ToArrayBuffer(payloadToDecrypt);
    const decryptedBuffer = await crypto.subtle.decrypt(
      {name: 'AES-CBC', iv: await this.#getIv(options)},
      await this.#getKey(options),
      data,
    );

    const decoder = new TextDecoder();
    const decryptedText = decoder.decode(decryptedBuffer);

    return this.#shouldSerialize(options) ? this.#deserialize(decryptedText) : decryptedText;
  }

  #shouldSerialize(options: Cindedi.EncryptOptions | Cindedi.DecryptOptions): boolean {
    if ('serialize' in options) return Boolean(options.serialize);
    if ('deserialize' in options) return Boolean(options.deserialize);

    return Boolean(this.configurations.serialize);
  }

  #shouldSign(options: Cindedi.EncryptOptions | Cindedi.DecryptOptions): boolean {
    if ('sign' in options) return Boolean(options.sign);

    return Boolean(this.configurations.sign);
  }

  #serialize(raw: any): string {
    return JSON.stringify(raw);
  }

  #deserialize(value: string): any {
    return JSON.parse(value);
  }

  async #getKey(options: Cindedi.EncryptOptions | Cindedi.DecryptOptions): Promise<CryptoKey> {
    const definitions = options.key ?? this.configurations.key;
    const rawKey = this.#hexStringToArrayBuffer(definitions.toString());
    return crypto.subtle.importKey('raw', rawKey, {name: 'AES-CBC'}, false, ['encrypt', 'decrypt']);
  }

  async #getIv(options: Cindedi.EncryptOptions | Cindedi.DecryptOptions): Promise<ArrayBuffer> {
    const definitions = options.iv ?? this.configurations.iv;
    return this.#hexStringToArrayBuffer(definitions.toString());
  }

  async #sign(value: string): Promise<string> {
    const signature = await crypto.subtle.digest(
      {name: 'SHA-128'},
      new TextEncoder().encode(value + this.configurations.key),
    );
    return `${value}.${this.#arrayBufferToBase64(signature)}`;
  }

  async #unsign(value: string): Promise<string> {
    const [payload, signature] = value.split('.');
    const expectedSignature = await crypto.subtle.digest(
      {name: 'SHA-128'},
      new TextEncoder().encode(payload + this.configurations.key),
    );

    return this.#arrayBufferToBase64(expectedSignature) === signature ? payload : '';
  }

  #hexStringToArrayBuffer(hexString: string): ArrayBuffer {
    const bytes = new Uint8Array(hexString.match(/.{1,2}/g)!.map((byte) => parseInt(byte, 16)));
    return bytes.buffer;
  }

  #arrayBufferToBase64(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer);
    const binary = bytes.reduce((acc, byte) => acc + String.fromCharCode(byte), '');
    return btoa(binary);
  }

  #base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binary = atob(base64);
    const bytes = new Uint8Array(binary.length);
    for (let i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }
    return bytes.buffer;
  }
}
