import {RemixBrowser} from '@remix-run/react';
import {startTransition, StrictMode} from 'react';
import {hydrateRoot} from 'react-dom/client';
import {createKernel} from '@cindedi/kernel';
import {defer} from '@cindedi/utilities/defer';
import {ApplicationProvider} from '@cindedi/react-application';

export const RemixReactDomKernel = createKernel(() => {
  return {
    name: 'remix-react-dom',

    async run(application) {
      const ready = defer();

      startTransition(() => {
        hydrateRoot(
          document,
          <StrictMode>
            <ApplicationProvider app={application}>
              <RemixBrowser />
            </ApplicationProvider>
          </StrictMode>,
        );
        ready.resolve();
      });

      return await ready.promise;
    },
  };
});
