import {Configuration} from '@cindedi/configuration';
import type {Configurations, EnvironmentVariables, Scopes, Services} from '@cindedi/spec';
import type {Cindedi} from '@cindedi/spec/application';
import type {ScopeArgs} from '@cindedi/spec/utilities/ScopeArgs';
import type {ScopeReturn} from '@cindedi/spec/utilities/ScopeReturn';
import {Environment} from '@cindedi/environment';
import {AggregateCindediError, CindediError, normalizeError} from '@cindedi/error';
import {Event, EventEmitter} from '@cindedi/event-emitter';
import {LifecycleContainer} from '@cindedi/lifecycle-container';
import {ServiceContainer} from '@cindedi/service-container';
import {Telemetry} from '@cindedi/telemetry';
import {defer, type Defer} from '@cindedi/utilities/defer';
import {generateId} from '@cindedi/utilities/generateId';
import {capitalize} from '@cindedi/utilities/capitalize';
import {mergeApplicationOptions} from '../utilities/mergeApplicationOptions';
import {DEFAULT_APPLICATION_OPTIONS} from '../constants/defaults';

export class Application<Return = any, Args extends any[] = []>
  extends EventEmitter<Cindedi.ApplicationEvents>
  implements Cindedi.Application<Return, Args>
{
  private _willTerminate: boolean = false;
  private _error: AggregateCindediError;
  private _options: Required<Cindedi.ApplicationOptions<Return, Args>>;
  private _configuration: Cindedi.Configuration<Configurations>;
  private _container: Cindedi.ServiceContainer<Services>;
  private _environment: Cindedi.Environment<EnvironmentVariables>;
  private _id: string;
  private _kernel: Cindedi.Kernel<Return, Args>;
  private _ready: Defer<this>;
  private _scope: string;
  private _telemetry: Cindedi.Telemetry;
  private _lifecycleContainer: Cindedi.LifecycleContainer;
  private _applicationParent?: Application<any, any>;
  private _lifetimeTimer: Cindedi.TelemetryTimer;

  get id() {
    return this._id;
  }

  get container() {
    return this._container;
  }

  get environment() {
    return this._environment;
  }

  get telemetry() {
    return this._telemetry;
  }

  get configuration() {
    return this._configuration;
  }

  get kernel() {
    return this._kernel;
  }

  get ready() {
    return this._ready.promise;
  }

  get scope() {
    return this._scope;
  }

  get error() {
    return this._error.hasAny ? this._error : null;
  }

  get lifecycleContainer() {
    return this._lifecycleContainer;
  }

  constructor(
    options: Partial<Cindedi.ApplicationOptions<Return, Args>>,
    parent?: Application<any, any>,
    scope: string = 'global',
    values?: Partial<Services>,
  ) {
    super(parent as any);
    this._scope = scope;
    this._telemetry =
      parent?.telemetry.fork(this._scope, {detail: {owner: this}}, this as any) ??
      new Telemetry('application', options.telemetry, {detail: {owner: this}}, this as any);
    const timer = this._telemetry.measure('ready');
    this._lifetimeTimer = this._telemetry.measure('lifetime');
    this._applicationParent = parent;
    this._options = mergeApplicationOptions(DEFAULT_APPLICATION_OPTIONS, options);
    this._id = generateId('application');
    this._configuration = (parent?.configuration.fork(this._options.configurations) ??
      new Configuration(this._options.configurations, this)) as any;
    this._container =
      parent?.container.fork(values) ?? new ServiceContainer<Services>(values, this as any);
    this._environment =
      parent?.environment.fork(this._options.environment) ??
      new Environment(this._options.environment, this);
    this._kernel = this._options.kernel;
    this._ready = defer();
    this._error = new AggregateCindediError('Application Error');
    this._lifecycleContainer =
      parent?.lifecycleContainer.fork(
        this._options.serviceProviders,
        () => ({application: this}),
        this._telemetry,
        this as any,
      ) ??
      new LifecycleContainer(
        this._options.serviceProviders ?? [],
        () => ({application: this}),
        this._telemetry,
        this as any,
      );

    this._ready.promise.then(() => timer.stop()).catch(() => timer.fail());

    this._initialize();
  }

  async run(...args: Args): Promise<Return> {
    let ok = false;
    const timer = this._telemetry.measure('run');
    try {
      if (this._willTerminate) throw new CindediError('Cannot run a terminated application.');
      await this.ready;
      let result = undefined as Return;
      if (this._kernel.run) {
        result = await this._telemetry.measureAsyncCallback(
          `kernel.${this._kernel.name}.run`,
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          async () => this._kernel.run?.(this, ...args)!,
        );
      }

      this.dispatchEvent(new Event('application:running'));

      ok = true;
      return result;
    } catch (maybeError) {
      this._handleError(maybeError);
      throw this._error;
    } finally {
      timer.stop(ok);
    }
  }

  async terminate(): Promise<void> {
    let ok = false;
    const timer = this._telemetry.measure('terminate');
    try {
      if (this._willTerminate) return;
      this._willTerminate = true;

      await this.ready;
      await this._lifecycleContainer.parallel(this._getScopedLifecycle('terminate'), [this]);
      if (this._kernel.terminate) {
        await this._telemetry.measureAsyncCallback(
          `kernel.${this._kernel.name}.terminate`,
          async () => await this._kernel.terminate?.(this),
        );
      }
      ok = true;
    } catch (maybeError) {
      this._handleError(maybeError);
      throw this._error;
    } finally {
      timer.stop(ok);
      this._lifetimeTimer.stop();
      await this._telemetry.flush();
    }
  }

  createScope<
    Name extends keyof Scopes,
    Return = ScopeReturn<Name>,
    Args extends any[] = ScopeArgs<Name>,
  >(name: Name, values: Partial<Services> = {}): Application<Return, Args> {
    try {
      const defaultScopeOptions = this._options.scopes[name] ?? {};
      const scopeOptions = mergeApplicationOptions(
        DEFAULT_APPLICATION_OPTIONS,
        defaultScopeOptions,
      );
      return new Application(scopeOptions, this, name, values) as any;
    } catch (maybeError) {
      this._handleError(maybeError);
      throw this._error;
    }
  }

  private _getScopedLifecycle(lifecycle: string): keyof Cindedi.Lifecycles {
    if (this._applicationParent == null) return lifecycle as keyof Cindedi.Lifecycles;

    return `${lifecycle}${capitalize(this._scope)}` as keyof Cindedi.Lifecycles;
  }

  private async _initialize() {
    let ok = false;
    const timer = this._telemetry.measure('initialize');
    try {
      if (this._applicationParent == null) {
        await this._lifecycleContainer.parallel('registerEnvironment', [this]);
        await this._lifecycleContainer.sequential('bootEnvironment', [this]);
        await this._lifecycleContainer.parallel('registerConfiguration', [this]);
        await this._lifecycleContainer.sequential('bootConfiguration', [this]);
      }
      if (this._kernel.register) {
        await this._telemetry.measureAsyncCallback(
          `kernel.${this._kernel.name}.register`,
          async () => this._kernel.register?.(this),
        );
      }
      await this._lifecycleContainer.parallel(this._getScopedLifecycle('register'), [this]);
      await this._lifecycleContainer.sequential(this._getScopedLifecycle('boot'), [this]);
      if (this._applicationParent == null) {
        await this._lifecycleContainer.parallel('telemetry', [this]);
        if (this._options.telemetry.autoStart && this._applicationParent == null) {
          this._telemetry.start();
        }
      }

      if (this._kernel.boot) {
        await this._telemetry.measureAsyncCallback(`kernel.${this._kernel.name}.boot`, async () =>
          this._kernel.boot?.(this),
        );
      }
      this._ready.resolve(this);
      this.dispatchEvent(new Event('application:ready'));
      ok = true;
    } catch (maybeError) {
      this._handleError(maybeError);
      throw this._error;
    } finally {
      timer.stop(ok);
    }
  }

  private _handleError(maybeError: unknown) {
    const error = normalizeError(maybeError);
    this._error.push(error);
    this.dispatchEvent(new Event('error', this._error));
  }
}
