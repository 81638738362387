import {Application} from '@cindedi/application';
import {CookieProvider} from '@cindedi/browser-cookie-client/service-provider';
import {EncryptionServiceProvider} from '@cindedi/browser-crypto-encrypter/service-provider';
import {RemixReactDomKernel} from '@cindedi/remix-react-dom-kernel';
import {WindowEnvironmentProvider} from '@cindedi/window-environment';
import {AppServiceProvider} from '~/foundations/app/client';
import {OrganizationProvider} from '~/domains/organization/client';

// Create a new application instance
const app = new Application({
  kernel: RemixReactDomKernel(),
  serviceProviders: [
    AppServiceProvider(),
    WindowEnvironmentProvider(),
    CookieProvider(),
    EncryptionServiceProvider(({environment}) => ({
      key: environment.get('ENCRYPTION_PUBLIC_KEY', 'MISSING ENCRYPTION KEY')!,
      iv: environment.get('ENCRYPTION_PUBLIC_IV', 'MISSING ENCRYPTION IV')!,
    })),
    OrganizationProvider(),
  ],
});

// Optionally, you can listen to application events
app.addEventListener('error', (event) => console.error(event.data));
// app.addEventListener('telemetry:*', (event) =>
//   console.log(`[${event.data.name}]:`, Math.round(event.data.value) + 'ms'),
// );

// Run the application
export default app.run();

if (process.env.NODE_ENV === 'development') {
  (window as any).app = app;
}
