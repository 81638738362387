import type {Cindedi} from '@cindedi/spec/event-emitter';
import type {EventListenerRegistration} from '../types';
import type {GlobPath} from '@cindedi/spec/utilities/GlobPath';
import {Event} from './Event';
import {globToRegex} from '@cindedi/utilities/globToRegex';

export class EventEmitter<EventMap extends Record<any, any> = Record<any, any>>
  implements Cindedi.EventEmitter<EventMap>
{
  private _listeners: Array<EventListenerRegistration> = [];

  private _eventListenerParent: Cindedi.EventEmitter<EventMap> | null;

  constructor(parent: Cindedi.EventEmitter<EventMap> | null = null) {
    this._eventListenerParent = parent;
  }

  addEventListener<
    Type extends keyof EventMap | string | number | symbol = keyof EventMap,
    Paths extends keyof EventMap | string | number | symbol = GlobPath<keyof EventMap, Type>,
    Data extends EventMap[Paths] = EventMap[Paths],
  >(
    type: Type,
    callback: Cindedi.EventListener<Type, Data>,
    options?: Cindedi.AddEventListenerOptions | undefined,
  ): () => void {
    for (const listener of this._listeners) {
      if (listener.type === type && listener.callback === callback)
        return () => this.removeEventListener(type, callback, options);
    }

    this._listeners.push({
      type,
      regex: globToRegex(String(type)),
      callback,
      options,
    });

    return () => this.removeEventListener(type, callback, options);
  }

  removeEventListener<
    Type extends keyof EventMap | string | number | symbol = keyof EventMap,
    Paths extends keyof EventMap | string | number | symbol = GlobPath<keyof EventMap, Type>,
    Data extends EventMap[Paths] = EventMap[Paths],
  >(
    type: Type,
    callback: Cindedi.EventListener<Type, Data>,
    _options?: Cindedi.EventListenerOptions | undefined,
  ): void {
    this._listeners = this._listeners.filter(
      (listener) => listener.type !== type || listener.callback !== callback,
    );
  }

  dispatchEvent<
    Type extends keyof EventMap = keyof EventMap,
    Data extends EventMap[Type] = EventMap[Type],
  >(event: Cindedi.Event<Type, Data>): boolean {
    const currentEvent = new Event(event.type, event.data, {
      bubbles: event.bubbles,
      currentTarget: this,
      composedPath: event.composedPath(),
    });

    const currentType = String(currentEvent.type);
    const listeners = this._listeners.filter(
      (listener) => currentType.match(listener.regex) != null,
    );

    for (const {callback, options, type} of listeners) {
      if (!options?.signal || !options.signal.aborted) callback(currentEvent);
      if (options?.once) this.removeEventListener(type, callback);
      if (currentEvent.immediatePropagationStopped) break;
    }

    if (currentEvent.bubbles && !currentEvent.propagationStopped && this._eventListenerParent) {
      this._eventListenerParent.dispatchEvent(currentEvent);
    }

    return true;
  }
}
