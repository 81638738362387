import {signal} from '@preact/signals-core';

export function TitlebarApi(): Organization.TitlebarApi {
  const title = signal('');

  return {
    get title() {
      return title.value;
    },
    hide() {
      title.value = '';
      return this;
    },
    setTitle(value) {
      title.value = value;
      return this;
    },
  };
}
