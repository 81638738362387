import {isRecord} from '@cindedi/utilities/isRecord';

export function isError(maybeError: any): maybeError is Error {
  try {
    return (
      isRecord(maybeError) &&
      maybeError.message != null &&
      typeof maybeError.message === 'string' &&
      maybeError.stack != null &&
      typeof maybeError.stack === 'string'
    );
  } catch (e) {
    return false;
  }
}
