import type {PathsOf, PathValue} from '@cindedi/spec/utilities/DotNotation';

// export function getPath<T, P extends PathsOf<T>>(
//   obj: T,
//   path: P,
// ): PathValue<T, P> | undefined {
//   return (path as string)
//     .split(/[.[\]]/)
//     .filter(Boolean)
//     .reduce<PathValue<T, P>>((value, key) => (value as any)?.[key], obj as any);
// }

export function getPath<T, P extends PathsOf<T>>(obj: T, path: P): PathValue<T, P> | undefined {
  if (path === '') return obj as PathValue<T, P>;

  let result: any = obj;
  const keys = (path as string).split(/[.[\]]/).filter(Boolean);

  for (const key of keys) {
    if (result && typeof result === 'object' && key in result) {
      result = result[key];
    } else {
      return undefined;
    }
  }

  return result;
}
