import type {Cindedi} from '@cindedi/spec/application';

export function mergeApplicationOptions(
  target: Required<Cindedi.ApplicationOptions>,
  source: Partial<Cindedi.ApplicationOptions>,
): Required<Cindedi.ApplicationOptions> {
  return {
    kernel: source.kernel ?? target.kernel,
    serviceProviders: [...(target.serviceProviders ?? []), ...(source.serviceProviders ?? [])],
    telemetry: {...target.telemetry, ...source.telemetry},
    scopes: {...target.scopes, ...source.scopes},
    configurations: {...target.configurations, ...source.configurations},
    environment: {...target.environment, ...source.environment},
  };
}
