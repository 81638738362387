import {Event, EventEmitter} from '@cindedi/event-emitter';
import type {Cindedi} from '@cindedi/spec/environment';
import {generateId} from '@cindedi/utilities/generateId';

export class Environment<Variables extends Record<string, any> = Record<string, any>>
  extends EventEmitter<Cindedi.EnvironmentEvents>
  implements Cindedi.Environment<Variables>
{
  private _id = generateId('environment');

  private _parentEnvironment?: Cindedi.Environment<any>;

  private _definitions: Partial<Variables>;

  get id(): string {
    return this._id;
  }

  constructor(
    partial: Partial<Variables> = {},
    eventTarget?: EventEmitter | Environment<any>,
    parent?: Environment<any>,
  ) {
    super(eventTarget);
    this._definitions = partial;
    if (parent) {
      this._parentEnvironment = parent;
      this._parentEnvironment.addEventListener('environment:set', (event) => {
        this.dispatchEvent(event);
      });
    }
  }

  get<Name extends keyof Variables>(
    name: Name,
    fallback?: Variables[Name] | undefined,
  ): Variables[Name] | undefined {
    if (name in this._definitions) {
      return this._definitions[name];
    }

    if (this._parentEnvironment) {
      return this._parentEnvironment.get(name, fallback as Variables[Name]);
    }

    return fallback;
  }

  set(partial: Partial<Variables>): this {
    this._definitions = {...this._definitions, ...partial};
    this.dispatchEvent(new Event('environment:set', partial));
    return this;
  }

  has<Name extends keyof Variables>(name: Name): boolean {
    return name in this._definitions;
  }

  missing<Name extends keyof Variables>(name: Name): boolean {
    return !this.has(name);
  }

  fork<ForkedVariables extends Record<string, any>>(
    variables: Partial<ForkedVariables> = {},
    eventTarget?: EventEmitter,
  ): Cindedi.Environment<ForkedVariables & Variables> {
    return new Environment<ForkedVariables & Variables>(
      variables as ForkedVariables & Variables,
      eventTarget,
      this,
    );
  }
}
