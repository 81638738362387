import {CindediError} from '@cindedi/error';

export class MissingDependencyError extends CindediError {
  dependency: string | number | symbol;

  constructor(dependency: string | number | symbol) {
    super({
      title: 'Missing Dependency',
      status: 500,
      detail: `Dependency "${String(dependency)}" was not found. Did you remember to register it?`,
      extras: {dependency},
    });

    this.dependency = dependency;
  }
}
