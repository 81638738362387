import type {Cindedi} from '@cindedi/spec/error';
import {isRecord} from '@cindedi/utilities/isRecord';
import {CINDEDI_ERROR_ID} from '../constants/symbols';

export function isCindediError(maybeError: any): maybeError is Cindedi.Error {
  try {
    return (
      isRecord(maybeError) &&
      maybeError.message != null &&
      maybeError.stack != null &&
      maybeError.status != null &&
      maybeError.serialize != null &&
      maybeError[CINDEDI_ERROR_ID as keyof typeof maybeError] === true
    );
  } catch (e) {
    return false;
  }
}
