/* eslint-disable no-control-regex */
import type {Cindedi} from '@cindedi/spec/cookie';

export interface CookieAttributeHandler {
  check(key: string): boolean;
  setValue(cookie: Cindedi.Cookie, value: string): Cindedi.Cookie;
}

export const SAME_SITE_VALUES: Cindedi.CookieSameSite[] = ['none', 'lax', 'strict'];

export const attributeHandlers: CookieAttributeHandler[] = [
  {
    check(key) {
      return key.toLowerCase() === 'path';
    },
    setValue(cookie, value) {
      return {
        ...cookie,
        path: value,
      };
    },
  },
  {
    check(key) {
      return key.toLowerCase() === 'secure';
    },
    setValue(cookie) {
      return {
        ...cookie,
        secure: true,
      };
    },
  },
  {
    check(key) {
      return key.toLowerCase() === 'domain';
    },
    setValue(cookie, value) {
      return {
        ...cookie,
        domain: value,
      };
    },
  },
  {
    check(key) {
      return key.toLowerCase() === 'httponly';
    },
    setValue(cookie) {
      return {
        ...cookie,
        httpOnly: true,
      };
    },
  },
  {
    check(key) {
      return key.toLowerCase() === 'max-age';
    },
    setValue(cookie, value) {
      if (isNaN(Number(value)) || !isFinite(Number(value))) {
        throw TypeError('Invalid max age cookie attribute');
      }

      return {
        ...cookie,
        maxAge: Number(value),
      };
    },
  },
  {
    check(key) {
      return key.toLowerCase() === 'expires';
    },
    setValue(cookie, value) {
      return {
        ...cookie,
        expires: new Date(value),
      };
    },
  },
  {
    check(key) {
      return key.toLowerCase() === 'samesite';
    },
    setValue(cookie, value) {
      if (!SAME_SITE_VALUES.includes(value.toLowerCase() as Cindedi.CookieSameSite)) {
        return cookie;
      }

      return {
        ...cookie,
        sameSite: value.toLowerCase() as Cindedi.CookieSameSite,
      };
    },
  },
];

export const charValidation = /^[\u0009\u0020-\u007e\u0080-\u00ff]+$/;

export const cookieEncoder = (cookie: Cindedi.Cookie): string => {
  if (!charValidation.test(cookie.name)) {
    throw new TypeError('Invalid cookie name');
  }

  if (cookie.value == null) {
    throw new TypeError('Missing cookie value');
  }

  if (cookie.value && !charValidation.test(cookie.value)) {
    throw new TypeError('Invalid cookie value');
  }

  const encoded = [`${cookie.name}=${encodeURIComponent(cookie.value)}`];

  if (cookie.path) {
    if (!charValidation.test(cookie.path)) {
      throw new TypeError('Invalid cookie path');
    }

    encoded.push(`Path=${cookie.path}`);
  }

  if (cookie.secure) {
    encoded.push('Secure');
  }

  if (cookie.domain) {
    if (!charValidation.test(cookie.domain)) {
      throw new TypeError('Invalid cookie domain');
    }

    encoded.push(`Domain=${cookie.domain}`);
  }

  if (cookie.httpOnly) {
    encoded.push('HttpOnly');
  }

  if (
    cookie.maxAge != null &&
    !isNaN(cookie.maxAge) &&
    isFinite(cookie.maxAge) &&
    cookie.maxAge > -1
  ) {
    encoded.push(`Max-Age=${Math.floor(cookie.maxAge)}`);
  }

  if (cookie.expires && typeof cookie.expires.toUTCString === 'function') {
    encoded.push(`Expires=${cookie.expires.toUTCString()}`);
  }

  if (cookie.sameSite && SAME_SITE_VALUES.includes(cookie.sameSite as Cindedi.CookieSameSite)) {
    encoded.push(`SameSite=${cookie.sameSite.charAt(0).toUpperCase() + cookie.sameSite.slice(1)}`);
  }

  return encoded.join('; ');
};

export const cookieParser = (definition: string): Cindedi.Cookie[] => {
  const cookies: Cindedi.Cookie[] = [];
  const pairs = definition.replace(/\s+/g, ' ').trim().split(/; */);

  for (let i = 0; i < pairs.length; i += 1) {
    const pair = pairs[i];
    const equalsIndex = pair.indexOf('=') > 0 ? pair.indexOf('=') : pair.length;
    const key = pair.substr(0, equalsIndex).trim();
    const value = pair.substr(equalsIndex + 1, pair.length).trim();

    const attribute = attributeHandlers.find((handler) => handler.check(key));
    if (attribute) {
      if (cookies.length > 0) {
        const cookie = cookies.pop();
        if (cookie) cookies.push(attribute.setValue(cookie, value));
      }

      continue;
    }

    if (!value) {
      continue;
    }

    if (!cookies.find((cookie) => cookie.name === key)) {
      try {
        cookies.push({
          name: key,
          value: value[0] === '"' ? value.slice(1, -1) : decodeURIComponent(value),
        });
      } catch (e) {
        cookies.push({
          value,
          name: key,
        });
      }
    }
  }

  return cookies;
};
