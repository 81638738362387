import type {Cindedi} from '@cindedi/spec/telemetry';
import {Event, EventEmitter} from '@cindedi/event-emitter';
import {generateId} from '@cindedi/utilities/generateId';
import type {TelemetryQueueItem} from '../types';

export class Telemetry extends EventEmitter<Cindedi.TelemetryEvents> implements Cindedi.Telemetry {
  private _defaultOptions: Cindedi.TelemetryEntryOptions;
  private _eventTarget?: Cindedi.EventEmitter<Cindedi.TelemetryEvents>;
  private _id: string;
  private _measurements: Map<string, Cindedi.TelemetryTimer> = new Map();
  private _namespace: string;
  private _options: Cindedi.TelemetryOptions;
  private _parentTelemetry: Telemetry | undefined;
  private _queue: TelemetryQueueItem[] = [];
  private _flushTimer: NodeJS.Timeout | number | null = null;

  get id(): string {
    return this._id;
  }

  get namespace(): string {
    return this._parentTelemetry
      ? `${this._parentTelemetry.namespace}.${this._namespace}`
      : this._namespace;
  }

  get queue(): TelemetryQueueItem[] {
    return this._parentTelemetry ? this._parentTelemetry.queue : this._queue;
  }

  constructor(
    namespace: string,
    options: Cindedi.TelemetryOptions = {},
    defaultOptions: Cindedi.TelemetryEntryOptions = {},
    eventTarget?: Cindedi.EventEmitter<Cindedi.TelemetryEvents>,
    parent?: Telemetry,
  ) {
    super(eventTarget);
    this._eventTarget = eventTarget;
    this._id = generateId('telemetry');
    this._namespace = namespace;
    this._parentTelemetry = parent;
    this._defaultOptions = defaultOptions;
    this._options = {
      autoStart: true,
      flushInterval: 100,
      ...options,
    };
  }

  count(
    name: string,
    value: number = 1,
    options: Cindedi.TelemetryEntryOptions = {},
  ): Cindedi.CounterTelemetryEntry {
    options = {...this._defaultOptions, ...options};
    const entry: Cindedi.CounterTelemetryEntry = {
      value,
      createdAt: Date.now(),
      detail: options.detail ?? {},
      entryType: 'c',
      id: options.id ?? generateId('telemetry-entry'),
      name: `${this.namespace}.${name}`,
      tags: options.tags ?? {},
    };

    this._enqueue({target: this, entry});
    return entry;
  }

  increment(
    name: string,
    value: number = 1,
    options: Cindedi.TelemetryEntryOptions = {},
  ): Cindedi.CounterTelemetryEntry {
    const fullName = `${this.namespace}.${name}`;
    const current = this.queue.find(
      ({entry, target}) => target === this && entry.name === fullName && entry.entryType === 'c',
    );

    if (current) {
      const entry = current.entry as Cindedi.CounterTelemetryEntry;
      entry.value += value;
      options.detail = options.detail ? {...entry.detail, ...options.detail} : entry.detail;
      options.tags = options.tags ? {...entry.tags, ...options.tags} : entry.tags;

      return entry;
    }

    return this.count(name, value, options);
  }

  decrement(
    name: string,
    value: number = 1,
    options: Cindedi.TelemetryEntryOptions = {},
  ): Cindedi.CounterTelemetryEntry {
    return this.increment(name, -value, options);
  }

  gauge(
    name: string,
    value: number,
    options?: Cindedi.TelemetryEntryOptions | undefined,
  ): Cindedi.GaugeTelemetryEntry {
    options = {...this._defaultOptions, ...options};
    const entry: Cindedi.GaugeTelemetryEntry = {
      value,
      createdAt: Date.now(),
      detail: options?.detail ?? {},
      entryType: 'g',
      id: options?.id ?? generateId('telemetry-entry'),
      name: `${this.namespace}.${name}`,
      tags: options?.tags ?? {},
    };

    this._enqueue({target: this, entry});
    return entry;
  }

  histogram(
    name: string,
    value: number,
    options?: Cindedi.TelemetryEntryOptions | undefined,
  ): Cindedi.HistogramTelemetryEntry {
    options = {...this._defaultOptions, ...options};
    const entry: Cindedi.HistogramTelemetryEntry = {
      value,
      createdAt: Date.now(),
      detail: options?.detail ?? {},
      entryType: 'h',
      id: options?.id ?? generateId('telemetry-entry'),
      name: `${this.namespace}.${name}`,
      tags: options?.tags ?? {},
    };

    this._enqueue({target: this, entry});
    return entry;
  }

  meter(
    name: string,
    value: number,
    options?: Cindedi.TelemetryEntryOptions | undefined,
  ): Cindedi.MeterTelemetryEntry {
    options = {...this._defaultOptions, ...options};
    const entry: Cindedi.MeterTelemetryEntry = {
      value,
      createdAt: Date.now(),
      detail: options?.detail ?? {},
      entryType: 'm',
      id: options?.id ?? generateId('telemetry-entry'),
      name: `${this.namespace}.${name}`,
      tags: options?.tags ?? {},
    };

    this._enqueue({target: this, entry});
    return entry;
  }

  set(
    name: string,
    value: number,
    options?: Cindedi.TelemetryEntryOptions | undefined,
  ): Cindedi.SetTelemetryEntry {
    options = {...this._defaultOptions, ...options};
    const entry: Cindedi.SetTelemetryEntry = {
      value,
      createdAt: Date.now(),
      detail: options?.detail ?? {},
      entryType: 's',
      id: options?.id ?? generateId('telemetry-entry'),
      name: `${this.namespace}.${name}`,
      tags: options?.tags ?? {},
    };

    this._enqueue({target: this, entry});
    return entry;
  }

  value(
    name: string,
    value: string | number | boolean,
    options?: Cindedi.TelemetryEntryOptions | undefined,
  ): Cindedi.ValueTelemetryEntry {
    options = {...this._defaultOptions, ...options};
    const entry: Cindedi.ValueTelemetryEntry = {
      value,
      createdAt: Date.now(),
      detail: options?.detail ?? {},
      entryType: 'v',
      id: options?.id ?? generateId('telemetry-entry'),
      name: `${this.namespace}.${name}`,
      tags: options?.tags ?? {},
    };

    this._enqueue({target: this, entry});
    return entry;
  }

  measure(name: string, options: Cindedi.TelemetryEntryOptions = {}): Cindedi.TelemetryTimer {
    options = {...this._defaultOptions, ...options};
    const timer: Cindedi.TelemetryTimer = {
      name: `${this.namespace}.${name}`,
      startTime: performance.now(),
      tags: options.tags ?? {},
      detail: options.detail ?? {},
      id: options.id ?? generateId('telemetry-entry'),
      set(value) {
        this.detail = {...this.detail, ...value};
        return this;
      },
      setTags(tags) {
        this.tags = {...this.tags, ...tags};
        return this;
      },
      stop: (ok?: boolean) => {
        const entry: Cindedi.TimerTelemetryEntry = {
          createdAt: Date.now(),
          detail: timer.detail,
          entryType: 'ms',
          id: timer.id,
          name: timer.name,
          ok: ok ?? true,
          tags: timer.tags,
          value: performance.now() - timer.startTime,
        };
        this._measurements.delete(name);
        this._enqueue({target: this, entry});
        return entry;
      },
      fail: () => timer.stop(false),
      cancel: () => this.cancelMeasurement(name),
    };

    this._measurements.set(name, timer);
    return timer;
  }

  measureCallback<Return>(namespace: string, callback: () => Return): Return {
    let ok = false;
    const timer = this.measure(namespace);

    try {
      const response = callback();
      ok = true;
      return response;
    } finally {
      timer.stop(ok);
    }
  }

  async measureAsyncCallback<Return>(
    namespace: string,
    callback: () => Promise<Return>,
  ): Promise<Return> {
    let ok = false;
    const timer = this.measure(namespace);

    try {
      const response = await callback();
      ok = true;
      return response;
    } finally {
      timer.stop(ok);
    }
  }

  cancelMeasurement(name: string): boolean {
    return this._measurements.delete(name);
  }

  endMeasurement(
    name: string,
    options: Cindedi.TelemetryEntryOptions = {},
  ): Cindedi.TimerTelemetryEntry | null {
    const timer = this._measurements.get(name);

    if (!timer) return null;
    if (options.tags) timer.setTags(options.tags);
    if (options.detail) timer.set(options.detail);

    return timer.stop();
  }

  start() {
    if (this._parentTelemetry) return this;

    if (!this._flushTimer) {
      this._flushTimer = setInterval(() => this.flush(), this._options.flushInterval);
    }

    return this;
  }

  stop() {
    if (this._parentTelemetry) return this;

    if (this._flushTimer) {
      clearInterval(this._flushTimer);
      this._flushTimer = null;
    }

    return this;
  }

  setDefaultOptions(options: Cindedi.DefaultOptions): this {
    this._defaultOptions = {...this._defaultOptions, ...options};
    return this;
  }

  fork(
    namespace: string,
    defaultOptions: Cindedi.DefaultOptions = {},
    eventTarget: Cindedi.EventEmitter<Cindedi.TelemetryEvents> | undefined = this._eventTarget,
  ): Cindedi.Telemetry {
    return new Telemetry(namespace, this._options, defaultOptions, eventTarget, this);
  }

  flush(): Promise<void> {
    return new Promise((resolve) => {
      if (this._parentTelemetry) return this._parentTelemetry.flush().then(resolve);

      while (this.queue.length) {
        const {target, entry} = this.queue.shift() ?? {};
        if (entry && target) {
          setTimeout(() => {
            target.dispatchEvent(new Event(`telemetry:${entry.name}`, entry));
          });
        }
      }

      setTimeout(resolve);
    });
  }

  private _enqueue(item: TelemetryQueueItem): number {
    if (this._parentTelemetry) return this._parentTelemetry._enqueue(item);

    return this.queue.push(item);
  }
}
