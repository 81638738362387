import type {Cindedi as _} from '@cindedi/spec/error';
import {CindediError} from '@cindedi/error';

export class CircularDependencyError extends CindediError {
  breadcrumbs: (string | number | symbol)[];

  constructor(breadcrumbs: (string | number | symbol)[]) {
    super({
      title: 'Circular Dependency',
      status: 500,
      detail: `Found a circular dependency while trying to resolve "${String(breadcrumbs[0])}": ${breadcrumbs.join(' > ')}`,
    });

    this.breadcrumbs = breadcrumbs;
  }
}
