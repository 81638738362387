import {createServiceProvider} from '@cindedi/service-provider';

export const AppServiceProvider = createServiceProvider(() => {
  return {
    name: 'application',

    registerConfiguration({environment, configuration}) {
      configuration.set('app', {
        name: environment.get('APP_NAME', 'Cindedi')!,
        url: environment.get('APP_URL', 'http://www.cindedi.test')!,
      });
    },
  };
});
