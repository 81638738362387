import {createServiceProvider} from '@cindedi/service-provider';
import {TitlebarApi} from './apis/titlebar';
import {MenuApi} from './apis/menu';

export const OrganizationProvider = createServiceProvider(() => {
  return {
    name: 'organization',

    register(application) {
      application.container.value('organization/menu', MenuApi());
      application.container.value('organization/titlebar', TitlebarApi());
    },
  };
});
