import type {Cindedi} from '@cindedi/spec/application';

export const DEFAULT_APPLICATION_OPTIONS: Required<Cindedi.ApplicationOptions> = {
  kernel: {name: 'noop'},
  telemetry: {
    autoStart: true,
    flushInterval: 100,
  },
  scopes: {},
  configurations: {},
  environment: {},
  serviceProviders: [],
};
