import type {Cindedi} from '@cindedi/spec/service-container';
import type {Constructor} from '@cindedi/spec/utilities/Constructor';

export type Token<T = any> = string | number | symbol | T | Constructor<T>;

export interface Binding<T = any> {
  factory(container: Cindedi.ServiceContainer): T;
  isSingleton: boolean;
  reference: Token;
  value?: T;
  original: unknown;
  dependencies: Token[];
}

export class Bindings {
  definitions: Binding<unknown>[] = [];

  has(reference: Token): boolean {
    return this.definitions.some((binding) => binding.reference === reference);
  }

  get(reference: Token): Binding<unknown> | undefined {
    return this.definitions.find((binding) => binding.reference === reference);
  }

  set(binding: Binding<unknown>): Binding<unknown> {
    if (this.has(binding.reference)) {
      this.definitions = this.definitions.map((current) =>
        binding.reference === current.reference ? binding : current,
      );
    } else {
      this.definitions.push(binding);
    }

    return binding;
  }
}
