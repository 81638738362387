import {generateId} from '@cindedi/utilities/generateId';
import type {Cindedi} from '@cindedi/spec/service-container';
import type {Constructor} from '@cindedi/spec/utilities/Constructor';
import {Event, EventEmitter} from '@cindedi/event-emitter';
import {InvalidServiceDefinitionError} from '../errors/InvalidServiceDefinitionError';
import {MissingDependencyError} from '../errors/MissingDependencyError';
import {isFactoryServiceDefinition} from '../guards/isFactoryServiceDefinition';
import {isServiceDefinition} from '../guards/isServiceDefinition';
import {createServiceFactory} from '../utilities/createServiceFactory';
import type {Binding} from './Bindings';
import {Bindings} from './Bindings';

export class ServiceContainer<Services extends Record<any, any> = Record<any, any>>
  extends EventEmitter<Cindedi.ServiceContainerEvents<Services>>
  implements Cindedi.ServiceContainer<Services>
{
  private _id: string;
  private _bindings: Bindings;
  private _parentServiceContainer?: ServiceContainer<Services>;
  private _eventEmitter?: Cindedi.EventEmitter<Cindedi.ServiceContainerEvents<Services>>;

  get id() {
    return this._id;
  }

  constructor(
    values?: Partial<Services>,
    eventEmitter?: Cindedi.EventEmitter<Cindedi.ServiceContainerEvents<Services>>,
    parent?: ServiceContainer<Services>,
  ) {
    super(eventEmitter);
    this._eventEmitter = eventEmitter;
    this._parentServiceContainer = parent;
    this._bindings = new Bindings();
    this._id = generateId('service-container');

    if (values) {
      Object.entries(values).forEach(([namespace, value]) => {
        this.value(namespace as keyof Services, value);
      });
    }
  }

  register<Namespace extends keyof Services>(
    namespace: Namespace,
    service: Constructor<Services[Namespace]>,
  ): this;
  register<Namespace extends keyof Services>(
    serviceDefinition: Cindedi.ServiceDefinition<Services, Namespace>,
  ): this;
  register<Namespace extends keyof Services>(
    namespaceOrServiceDefinition: Namespace | Cindedi.ServiceDefinition<Services, Namespace>,
    service?: Constructor<Services[Namespace]>,
  ): this {
    let binding: Binding<Services[Namespace]>;
    if (typeof namespaceOrServiceDefinition === 'string' && service) {
      binding = {
        reference: namespaceOrServiceDefinition,
        isSingleton: false,
        factory: () => new service(),
        original: service,
        dependencies: [],
      };
    } else if (isServiceDefinition(namespaceOrServiceDefinition)) {
      binding = {
        reference: namespaceOrServiceDefinition.namespace,
        isSingleton: false,
        factory: createServiceFactory(this, namespaceOrServiceDefinition.namespace as Namespace),
        original: namespaceOrServiceDefinition.value,
        dependencies: namespaceOrServiceDefinition.dependencies,
      };
    } else {
      throw new InvalidServiceDefinitionError(namespaceOrServiceDefinition);
    }

    this._bindings.set(binding);
    this.dispatchEvent(
      new Event('service-container:set', {
        containerId: this._id,
        namespace: binding.reference,
      }),
    );

    return this;
  }

  singleton<Namespace extends keyof Services>(
    namespace: Namespace,
    service: new () => Services[Namespace],
  ): this;
  singleton<Namespace extends keyof Services>(
    serviceDefinition: Cindedi.ServiceDefinition<Services, Namespace>,
  ): this;
  singleton<Namespace extends keyof Services>(
    namespaceOrServiceDefinition: Namespace | Cindedi.ServiceDefinition<Services, Namespace>,
    service?: Constructor<Services[Namespace]>,
  ): this {
    let binding: Binding<Services[Namespace]>;
    if (typeof namespaceOrServiceDefinition === 'string' && service) {
      binding = {
        reference: namespaceOrServiceDefinition,
        isSingleton: true,
        factory: () => new service(),
        original: service,
        dependencies: [],
      };
    } else if (isServiceDefinition(namespaceOrServiceDefinition)) {
      binding = {
        reference: namespaceOrServiceDefinition.namespace,
        isSingleton: true,
        factory: createServiceFactory(this, namespaceOrServiceDefinition.namespace as Namespace),
        original: namespaceOrServiceDefinition.value,
        dependencies: namespaceOrServiceDefinition.dependencies,
      };
    } else {
      throw new InvalidServiceDefinitionError(namespaceOrServiceDefinition);
    }

    this._bindings.set(binding);

    this.dispatchEvent(new Event('service-container:set', {namespace: binding.reference}));

    return this;
  }

  factory<Namespace extends keyof Services>(
    namespace: Namespace,
    factory: Cindedi.ServiceFactory<Services, Namespace>,
  ): this;
  factory<Namespace extends keyof Services>(
    serviceDefinition: Cindedi.FactoryServiceDefinition<Services, Namespace>,
  ): this;
  factory<Namespace extends keyof Services>(
    namespaceOrServiceDefinition: Namespace | Cindedi.FactoryServiceDefinition<Services, Namespace>,
    factory?: Cindedi.ServiceFactory<Services, Namespace>,
  ): this {
    let binding: Binding<Services[Namespace]>;
    if (typeof namespaceOrServiceDefinition === 'string' && factory) {
      binding = {
        reference: namespaceOrServiceDefinition,
        isSingleton: true,
        factory,
        original: factory,
        dependencies: [],
      };
    } else if (isFactoryServiceDefinition(namespaceOrServiceDefinition)) {
      binding = {
        reference: namespaceOrServiceDefinition.namespace,
        isSingleton: namespaceOrServiceDefinition.isSingleton,
        factory: namespaceOrServiceDefinition.factory,
        original: namespaceOrServiceDefinition.factory,
        dependencies: [],
      };
    } else {
      throw new InvalidServiceDefinitionError(namespaceOrServiceDefinition);
    }

    this._bindings.set(binding);

    this.dispatchEvent(new Event('service-container:set', {namespace: binding.reference}));

    return this;
  }

  value<Namespace extends keyof Services>(namespace: Namespace, value: Services[Namespace]): this {
    this._bindings.set({
      reference: namespace,
      isSingleton: true,
      factory: () => value,
      original: value,
      dependencies: [],
    });

    this.dispatchEvent(new Event('service-container:set', {namespace}));

    return this;
  }

  use<Namespace extends keyof Services>(namespace: Namespace): Services[Namespace] {
    const binding = this._getBinding<Namespace>(namespace);

    if (binding.isSingleton) {
      if (!binding.value) {
        binding.value = binding.factory(this);
      }

      return binding.value as Services[Namespace];
    }

    return binding.factory(this);
  }

  has<Namespace extends keyof Services>(namespace: Namespace): boolean {
    return (
      this._bindings.has(namespace) ||
      (this._parentServiceContainer && this._parentServiceContainer.has(namespace)) ||
      false
    );
  }

  missing<Namespace extends keyof Services>(namespace: Namespace): boolean {
    return !this.has(namespace);
  }

  fork(values?: Partial<Services>): Cindedi.ServiceContainer {
    const container = new ServiceContainer(values, this._eventEmitter, this);
    this.dispatchEvent(new Event('service-container:fork'));
    return container;
  }

  _getBinding<Namespace extends keyof Services>(
    namespace: Namespace,
  ): Binding<Services[Namespace]> {
    if (this._bindings.has(namespace)) {
      return this._bindings.get(namespace) as Binding;
    }

    if (this._parentServiceContainer && this._parentServiceContainer.has(namespace)) {
      const binding = this._parentServiceContainer._getBinding<Namespace>(namespace);

      if (binding.isSingleton && !binding.value) {
        const clone = {...binding};

        this._bindings.set(clone);

        return clone;
      }

      return binding;
    }

    throw new MissingDependencyError(namespace);
  }
}
