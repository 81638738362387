import type {Cindedi} from '@cindedi/spec/service-container';

export function isFactoryServiceDefinition(
  maybeFactoryServiceDefinition: any,
): maybeFactoryServiceDefinition is Cindedi.FactoryServiceDefinition<any> {
  return (
    maybeFactoryServiceDefinition != null &&
    typeof maybeFactoryServiceDefinition === 'object' &&
    !Array.isArray(maybeFactoryServiceDefinition) &&
    maybeFactoryServiceDefinition.factory != null &&
    maybeFactoryServiceDefinition.namespace != null &&
    typeof maybeFactoryServiceDefinition.isSingleton === 'boolean'
  );
}
